import React from 'react'
import { graphql } from 'gatsby'
import TagPage from '../components/templates/tag'


const TagsPage = ({pageContext, data}) => {
  const posts = data.allMarkdownRemark.edges
  const tag = pageContext.tag
  const tags = []

  const pagePosts = []

  posts.map( ({node}) => {
    if (node && node.frontmatter) {
        let el = node.frontmatter
        if (el.tags && el.tags.includes(tag)){
          pagePosts.push(node)
        }
        if (el.keywords && el.keywords.includes(tag)) {
          pagePosts.push(node)
        }
    }
  })

  pagePosts.map(( node ) => {
    if (node.frontmatter.tags) {
      node.frontmatter.tags.map(tag => {
        if (tags.indexOf(tag) === -1) {
          tags.push(tag)
        }
    })
    }

    if (node.frontmatter.keywords) {
      node.frontmatter.tags.map(tag => {
        if (tags.indexOf(tag) === -1) {
          tags.push(tag)
        }
    })
    }
  })

  

  return (
    <TagPage tag={pageContext.tag} articles={pagePosts} tags={tags} />
  )
  }

export default TagsPage

export const TagQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
      node {
        frontmatter {
          date
          tags
          keywords
          title
          url
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
  }
`
