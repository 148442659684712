import React from 'react'
import Page from '../page'
import Tags from '../../atoms/tags'
import Search from '../../molecules/search'
import ArticlesList from '../../organisms/article-list/'

const TagPage = (props) => {
  return (
    <Page>
        <Search classNames={'t-tag-page'} cols="5" lang="en" />
        <div className="row t-tag-page">
            <div className="col-md-8 t-tag-page__header">
                <h3>Articles about {props.tag}</h3>
                <ArticlesList posts={props.articles}/>
            </div>
            <div className="col-md-4 t-tag-page__navigation">
                <h3>Tags</h3>
                <Tags tags={props.tags}/>
            </div>
        </div>  
    </Page>
  )
}

export default TagPage